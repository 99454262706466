import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import EcoIndex from "./EcoIndex";
import OrganizerDetailsSidebar from "./OragniserDetailsSidebar"; // Corrected Typo
import OrganizerData from "./OrganizerData";
import { useEvent } from "./EventDetailsContext";
import AttendeeDetaileventDetailsSidebar from "./AttendeeDetaileventDetailsSidebar"; // Corrected Typo
import EventDetailsSidebar from "./eventDetailsSidebar"; // Assuming you meant this
import "./Organizer.css";

const Organizer = () => {
  const { eventDetails, setEventDetails } = useEvent();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (location.state?.eventDetails) {
      setEventDetails(location.state.eventDetails);
      setLoading(false);
    } else {
      console.error("Event not found");
      setLoading(false);
    }
  }, [location.state, setEventDetails]);

  if (loading) {
    return <div>Loading event details...</div>;
  }

  if (!eventDetails) {
    return <div>Error: Event details could not be loaded.</div>;
  }
  console.log("eventRole", eventDetails);

  return (
    <div className="organizer">
      <EcoIndex />
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center mb-3">
              <div class="main-content-header">UPDATE EVENT</div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end mb-3">
              <h1>{eventDetails?.eventName}</h1>
            </div>
          </div>
          <hr />
          <div className="row p-3">
            <div className="col-md-12 p-0 shadow-box">
              <div className="row">
                <div className="col-md-12">
                  {eventDetails?.myRole === "Attendee" ? (
                    <AttendeeDetaileventDetailsSidebar
                      eventDetails={eventDetails}
                    />
                  ) : eventDetails?.myRole === "Organizer" ? (
                    <OrganizerDetailsSidebar eventDetails={eventDetails} />
                  ) : (
                    <EventDetailsSidebar eventDetails={eventDetails} />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="organizer-content">
                    <Routes>
                      <Route
                        path="Organizer-info"
                        element={<OrganizerData eventDetails={eventDetails} />}
                      />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizer;
