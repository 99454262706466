import React from "react";
import "./NewAddInfoPopup.css";
import ecologo from "./assets/ecologo.png";

function NewAddInfoPopup({ onClose }) {
  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <img src={ecologo} alt="" srcset="" />
          <p>Your Emission</p>
          <button className="popup-close" onClick={onClose}>
            x
          </button>
        </div>

        <div className="popup-body">
          <div className="emission-summary">
            <div className="emission-summary-details">
              <p>Total Emission</p>
              <h1 className="total-emission"> <b>58.90</b> Kg CO2</h1>
              
              <div className="emission-summary-bar">
  <div className="percentage-bar">
    <div className="travel-bar">70%</div>
    <div className="accommodation-bar">30%</div>
    <div className="food-bar">20%</div>
  </div>
  <div className="emission-legend">
    <div>
      <span className="legend-color travel-color"></span> <b>Travel:</b> X Kg CO2
    </div>
    <div>
      <span className="legend-color accommodation-color"></span><b>Accommodation:</b>Y Kg CO2
    </div>
    <div>
      <span className="legend-color food-color"></span> <b>Food:</b>Z Kg CO2
    </div>
  </div>
</div>

            </div>

            <div className="emission-summary-meter">

              <p>Emission Meter</p>
            </div>
          </div>

          <div className="info-event">
            <div className="info-event-details">
              <h4>Event Details</h4>
              <p><b>Name:</b>ABC Tour</p>
              <p><b>Location:</b>New Delhi</p>
              <p><b>Dates:</b>16 Sept '24 - 20 Sept '24</p>
            </div>
            
            <div className="for-info">
           <p>By emitting less than the average range, you helped save.....</p>
            <div className="info-infographic">Infographic about impact</div>
            </div>
          </div>
        </div>

        <div className="popup-footer">
          <button className="sign-up-button">Sign Up</button>

<div>
          <button className="download-button">Download</button>
          <button className="share-button"><i class="fa fa-share-alt" style={{ color:"white", marginRight:"10px"}}></i>Share</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewAddInfoPopup;
