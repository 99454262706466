import React from "react";
import "./manupages.css";
import polyamide from "./assets/manu/polyamide.png";
import manu2 from "./assets/manu/manu2.jpg";
import Lottie from "lottie-react";
import gear from "./assets/anim/gear.json";
import manucar from './assets/manu/manucar.json'
import tree from './assets/manu/tree.json'
import polyprocess from './assets/manu/polyprocess.png'

const PolyamideCage = () => {


  return (
    <div className="cage">
      <div className="manu-logo-pages">
        <img src={manu2} alt="Manu Logo" />
      </div>

      <div className="cage-container">
      <div className="process-flow">
      
  <img src={polyprocess} alt="Manu Logo" />
  <h2>Process Flow</h2>
  
 </div>
        <div className="product-image-container">
        <p className="product-title">Polyamide Cage</p>
          <img src={polyamide} alt="Product A" className="product-image" />
        </div>

        <div className="diagram">
         
         <ul>
           <li>
             {" "}
             <Lottie animationData={gear} className="gear" />Total Emission (Production) <br></br>
             </li>
<li className="highlighted-text"> <span>29.11 tCO₂</span></li>

           <li>
             {" "}
             <Lottie animationData={gear} className="gear" /> Per Piece Emission<br></br>
           
           </li>
           <li className="highlighted-text"> <span>1.01 g CO₂e</span> </li>           <li>
             {" "}
             <Lottie animationData={gear} className="gear" /> Specific Energy Consumption<br></br>
              
           </li>
           <li className="highlighted-text"> <span>2.88 W</span></li>
         </ul>
       </div>
      </div>
      <div className="manu-bottom">

<div className="manu-emissions-image">
   <Lottie animationData={manucar} className="manu-images" />
   <Lottie animationData={tree} className="manu-images2" />
</div>

<div className="manu-emissions">
          <p>
          The Passenger Car shall be driven <b>1,45,559.44 Km</b><br></br>
          to emit this amount of carbon
          </p>
          <p>=</p>
          <p>
            <b>1164</b> number of tress will be required to <br></br>offset this
            much emission in 1 Year.{" "}
          </p>
        </div>

   <div className="manu-footer">
     <p>Securing the right move...</p>
   </div>

   </div>
    </div>
  );
};

export default PolyamideCage;
