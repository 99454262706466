import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const sections = [
    { 
      title: "Introduction", 
      content: "At EcoIndex, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information when you use our platform to track carbon emissions from events and participate in our carbon credit program." 
    },
    { 
      title: "Information We Collect", 
      content: "We collect personal data (e.g., Name, Email, Phone Number) and event-specific data (e.g., Travel Data, Food Data) to calculate carbon emissions. Automatically collected data includes IP addresses, cookies, and device information." 
    },
    { 
      title: "How We Use Your Data", 
      content: "Data is used for carbon emission tracking, customer support, marketing (optional), and service improvements. Your data is also used for compliance with legal obligations." 
    },
    { 
      title: "Sharing Your Data", 
      content: "We do not sell your data. Data may be shared with service providers, carbon credit partners, or to comply with legal obligations. All third parties are contractually bound to protect your data." 
    },
    { 
      title: "Data Security", 
      content: "We implement industry-standard measures such as encryption, secure storage, and regular assessments to protect your data from unauthorized access or misuse." 
    },
    { 
      title: "Data Retention", 
      content: "Your data is retained as long as necessary for service delivery or legal compliance. Personal data can be deleted upon request, subject to legal requirements." 
    },
    { 
      title: "Your Rights", 
      content: "You have rights to access, rectify, erase, or restrict your data, as well as the right to data portability. Contact us at privacy@ecoindex.ai to exercise these rights." 
    },
    { 
      title: "Cookies and Tracking Technologies", 
      content: "We use cookies to enhance your experience, analyze traffic, and customize content. You can manage cookies via browser settings." 
    },
    { 
      title: "Carbon Credit Program", 
      content: "If you participate in the program, data may be shared with third-party verifiers. All partners adhere to strict data privacy standards." 
    },
    { 
      title: "Changes to Privacy Policy", 
      content: "We may update this Privacy Policy periodically. Significant changes will be communicated via email or a prominent notice on our website." 
    },
    { 
      title: "Contact Us", 
      content: "For questions about this Privacy Policy or your data, contact us at connect@ecoindex.ai." 
    }
  ];

  return (
    <div className="privacy-container">
      <h1 className="privacy-title">Privacy Policy</h1>
      {sections.map((section, index) => (
        <div key={index} className="privacy-section">
          <h2 className="section-title">{section.title}</h2>
          <p className="section-content">{section.content}</p>
        </div>
      ))}
    </div>
  );
};

export default PrivacyPolicy;
