import React from "react";
import Ecoindex_V2 from './assets/Ecoindex_V2.mp4'

import "./AboutEcoIndex.css";
const AboutEcoIndex = () => {
  return (
    <div className="about-container">
      <div className="video-container">
        <h1>About EcoIndex</h1>
        <video className="responsive-video" height={600} controls autoPlay>
          <source
            src="https://api.ecoindex.ai/download/assets/Ecoindex_V2.706b0d70f816087ca819.mp4"
            // src = {Ecoindex_V2}
            // type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default AboutEcoIndex;
