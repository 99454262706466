import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  const sections = [
    { title: "Introduction", content: "Welcome to EcoIndex, a platform designed to help users track and reduce their carbon emissions. By signing up, you agree to comply with these Terms and Conditions." },
    { title: "Eligibility", content: "Our website is open to all users, regardless of age or background. By using the site, you agree to comply with our terms and conditions." },
    { title: "User Responsibilities", content: "Users agree to provide accurate and truthful information when using our carbon tracking tools. You are solely responsible for compliance with environmental laws." },
    {
      title: "Our Relationship to You",
      content: "We provide the EcoIndex Tool to help plan and deliver sustainable events. We are not liable for errors or omissions in your usage.",
    },
    { title: "Data Collection and Privacy", content: "By using our services, you consent to data collection and usage as outlined in our Privacy Policy." },
    {
      title: "Intellectual Property Rights",
      content: "All intellectual property belongs to EcoIndex. Do not use materials without permission.",
    },
    {
      title: "Limitations of Liability",
      content: "EcoIndex is not responsible for inaccuracies in carbon emission data or actions taken based on the data.",
    },
    {
      title: "User-Generated Content",
      content: "You are responsible for any content you upload. We reserve the right to remove any inappropriate content.",
    },
    { title: "Termination of Use", content: "We reserve the right to terminate access for violations of terms or laws." },
    { title: "Third-Party Links and Services", content: "We are not responsible for third-party websites linked on our site." },
    { title: "Governing Law", content: "These terms are governed by the laws of your location." },
    {
      title: "Changes to Terms and Conditions",
      content: "We may update these Terms and Conditions and notify registered users via email.",
    },
    { title: "Contact Information", content: "If you have any questions, contact us at connect@ecoindex.ai." },
  ];

  return (
    <div className="terms-container">
      <h1 className="title">EcoIndex Terms and Conditions</h1>
      {sections.map((section, index) => (
        <div key={index} className="section">
          <h2 className="section-title">{section.title}</h2>
          <p className="section-content">{section.content}</p>
        </div>
      ))}
    </div>
  );
};

export default TermsAndConditions;
