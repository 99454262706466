import React from "react";
import manu2 from "./assets/manu/manu2.jpg";
import electronics from './assets/manu/electronics.png'


const ManuBmw = () => {
  

  return (
    <div className="cage">
      <div className="manu-logo-pages">
        <img src={manu2} alt="Manu Logo" />
      </div>
      
        <div className="electronics-label annual-reduction"><b>1 hour </b>of Daily Commute<br></br> by <b>Car</b> emits approximately <li className="electronics-text"> <span> 3,360 g CO₂e</span></li></div>
       
        <div className="electronics-label-o total-product">In <b>4 hours</b>, a standard <br></br><b>100W</b> lightbulb emits about <li className="electronics-text-o"> <span> 172 g CO₂e</span></li></div>

        <div className="electronics-label-o manu-energy-consumption"><b>10 minutes</b> of <b>Hot Shower</b><br></br> emits approximately<li className="electronics-text-o"> <span> 2000 g CO₂e</span></li></div>


        <div className="electronics-label-o carbon-emission"><b>Per Day</b> of <b>Mobile Phone</b><br></br> Usage emits approximately<li className="electronics-text-o"> <span> 189 g CO₂e</span></li></div>


      <div className="car-container">
           <img src={electronics} alt="BMW M2 Coupe" />
           </div>
    </div>
  );
};

export default ManuBmw;
