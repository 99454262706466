import React from "react";
import "./AboutEcoIndex.css";

const WalkThrough = () => {
  return (
    <div className="about-container">
      <div className="video-container">
        <h1>WalkThrough</h1>
        <video className="responsive-video" height={600} controls autoPlay>
          <source
            src="/walkthru.mp4"
            // src = {Ecoindex_V2}
            // type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default WalkThrough;
