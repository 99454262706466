import React, { useState, useEffect } from "react";
import NewAddInfoPopup from "./NewAddInfoPopup";
import "./NewAddInfo.css";

function Dropdown({ options, value, onChange, disabled = false, style = {} }) {
  return (
  
    <select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      style={{
        backgroundColor: disabled ? "#f8f9fa" : "",
        color: disabled ? "#f8f9fa" : "",
        borderColor: disabled ? "#f8f9fa" : "",
        ...style,
      }}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

  );
}

function HeaderForm({ travelMode, setTravelMode, stay, setStay }) {
  const [mealPreference, setMealPreference] = useState("No Meals");


  return (
    <div className="header-form">
      <div className="header-events">
      <label>
          <p>Event Name</p>
          <input
            type="text"
            placeholder="Event Name"
            className="header-input"
          />
        </label>
       
       <div className="user-info">
        <label>
          <p>Name</p>
          <input
            type="text"
            placeholder="Enter Name"
            className="header-input"
          />
        </label>
        <label>
          <p>Email ID</p>
          <input
            type="email"
            placeholder="Enter Email"
            className="header-input"
          />
        </label>
        </div>

      </div>

      <div className="header-dropdowns">
       
       <div className="user-info">
        <label>
          <p>Primary Travel Mode</p>
          <Dropdown
            options={[
              "Bus",
              "Train",
              "Flight",
              "Metro",
              "Car Pool",
              "Car/Cab",
              "2-Wheeler",
              "Bicycle/Walking",
              "3-Wheeler",
            ]}
            value={travelMode}
            onChange={setTravelMode}
            className="header-dropdown"
          />
        </label>
        <label>
          <p>Meal Preference</p>
          <Dropdown
            options={["No Meal", "Veg", "Non Veg", "Vegan"]}
            value={mealPreference}
            onChange={setMealPreference}
             className="header-dropdown"
          />
        </label>
        </div>

        <div className="stay-duration-box">
        
        <div className="user-info">
         
          <label className="stay-duration-label">
            <p>Stay at Hotel?</p>
            <Dropdown
              options={["Yes", "No"]}
              value={stay}
              onChange={setStay}
            className="header-dropdown"
            />
          </label>
          </div>
        </div>
      </div>
    </div>
  );
}

function Stop({ label, location, onLocationChange, removable, onRemove }) {
  return (
    <div
      className={`timeline-stop ${
        label === "Start Location"
          ? "start"
          : label === "Event Venue"
          ? "end"
          : ""
      }`}
    >
      <div
        className={`circle ${
          label === "Start Location"
            ? "start-circle"
            : label === "Event Venue"
            ? "end-circle"
            : "mid-circle"
        }`}
      ></div>

      <span
        className={`stop-label ${
          label === "Start Location"
            ? "start-label"
            : label === "Event Venue"
            ? "end-label"
            : "mid-label"
        }`}
      >
        {label}
      </span>

      <div className="stop-controls">
        <input
          type="text"
          placeholder="City / Zip Code"
          value={location}
          onChange={(e) => onLocationChange(e.target.value)}
        />
        {removable && (
          <button className="remove-stop" onClick={onRemove}>
            -
          </button>
        )}
      </div>
    </div>
  );
}

function Timeline({ travelMode, stay }) {
  const defaultStops = {
    Bus: [
      { label: "Start Location", location: "" },
      { label: "Bus Stand 01", location: "" },
      { label: "Bus Stand 02", location: "" },
      { label: "Event Venue", location: "" },
    ],
    Train: [
      { label: "Start Location", location: "" },
      { label: "Train Station 01", location: "" },
      { label: "Train Station 02", location: "" },
      { label: "Event Venue", location: "" },
    ],
    Flight: [
      { label: "Start Location", location: "" },
      { label: "Airport 01", location: "" },
      { label: "Airport 02", location: "" },
      { label: "Event Venue", location: "" },
    ],
    "Car Pool": [
      { label: "Start Location", location: "" },
      { label: "Carpool Pickup 01", location: "" },
      { label: "Carpool Pickup 02", location: "" },
      { label: "Event Venue", location: "" },
    ],
    "Car/Cab": [
      { label: "Start Location", location: "" },
      { label: "Event Venue", location: "" },
    ],
    "2-Wheeler": [
      { label: "Start Location", location: "" },
      { label: "Event Venue", location: "" },
    ],
    "Bicycle/Walking": [
      { label: "Start Location", location: "" },
      { label: "Event Venue", location: "" },
    ],

    Metro: [
      { label: "Start Location", location: "" },
      { label: "Metro Station 01", location: "" },
      { label: "Metro Station 02", location: "" },
      { label: "Event Venue", location: "" },
    ],

    "3-Wheeler": [
      { label: "Start Location", location: "" },
      { label: "Stop 01", location: "" },
      { label: "Event Venue", location: "" },
    ],
    default: [
      { label: "Start Location", location: "" },
      { label: "Event Venue", location: "" },
    ],
  };

  const fuelOptions = {
    Bus: ["Fuel-based", "EV"],
    Flight: ["Economy", "Business"],
    default: ["Petrol", "Diesel", "CNG", "EV", "Hybrid"],
  };

  const [stops, setStops] = useState(defaultStops[travelMode] || []);
  const [travelModes, setTravelModes] = useState(["Bus"]);
  const [fuelTypes, setFuelTypes] = useState(stops.map(() => ""));

  const travelModeEmojis = {
    Bus: "🚌",
    Cab: "🚖",
    Walk: "🚶",
    "3-Wheeler": "🛺",
    "Car-Pool": "🚐",
    "2-Wheeler": "🏍️",
    Metro: "🚉",
    Train: "🚆",
    Flight: "✈️",
  };

  useEffect(() => {
    const initialStops = defaultStops[travelMode] || [];
    console.log("initialStops", initialStops);
  
    // Preserve Hotel if `stay` is "Yes" and travelMode changes
    setStops((prevStops) => {
      if (stay === "Yes") {
        const hasHotelStop = prevStops.some((stop) => stop.label === "Hotel");
        if (hasHotelStop) {
          const eventVenueIndex = initialStops.findIndex(
            (stop) => stop.label === "Event Venue"
          );
          if (eventVenueIndex > 0) {
            const newStops = [...initialStops];
            newStops.splice(eventVenueIndex, 0, {
              label: "Hotel",
              location: "",
            });
            return newStops;
          }
        }
      }
      return initialStops;
    });
  
    // Dynamically determine travel modes
    const initialTravelModes = initialStops.map((stop, index) => {
      
      // Specific logic for 2-Wheeler and 3-Wheeler
      if (travelMode === "2-Wheeler" || travelMode === "3-Wheeler") {
        return travelMode;
      }
  
      // Specific logic for "Car/Cab"
      if (travelMode === "Car/Cab") {
        if (
          stop.label === "Start Location" &&
          initialStops[index + 1]?.label === "Event Venue"
        ) {
          return "Cab";
        }
        return "Walk"; // Default for other routes
      }
  
      // General logic for other travel modes
      if (
        (stop.label === "Bus Stand 01" &&
          initialStops[index + 1]?.label === "Bus Stand 02") ||
        (stop.label === "Train Station 01" &&
          initialStops[index + 1]?.label === "Train Station 02") ||
        (stop.label === "Airport 01" &&
          initialStops[index + 1]?.label === "Airport 02") ||
        (stop.label === "Carpool Pickup 01" &&
          initialStops[index + 1]?.label === "Carpool Pickup 02") ||
        (stop.label === "Metro Station 01" &&
          initialStops[index + 1]?.label === "Metro Station 02")
      ) {
        switch (stop.label) {
          case "Bus Stand 01":
            return "Bus";
          case "Train Station 01":
            return "Train";
          case "Airport 01":
            return "Flight";
          case "Carpool Pickup 01":
            return "Car-Pool";
          case "Metro Station 01":
            return "Metro";
          default:
            return "Walk";
        }
      }
  
      // Fallback travel mode
      return "Walk";
    });
  
    setTravelModes(initialTravelModes);
  
    // Reset fuel types for all stops
    setFuelTypes(initialStops.map(() => ""));
  }, [travelMode]);
  
  
  

  const handleAddStop = (index) => {
    const newStop = { label: "New Stop", location: "" };
    const updatedStops = [...stops];
    updatedStops.splice(index + 1, 0, newStop);
    setStops(updatedStops);

    const updatedModes = [...travelModes];
    updatedModes.splice(index + 1, 0, "Walk");
    setTravelModes(updatedModes);
  };

  const handleRemoveStop = (index) => {
    const updatedStops = stops.filter((_, i) => i !== index);
    setStops(updatedStops);

    const updatedModes = travelModes.filter((_, i) => i !== index);
    setTravelModes(updatedModes);
  };

  const handleTravelModeChange = (index, value) => {
    const updatedModes = [...travelModes];
    updatedModes[index] = value;
    setTravelModes(updatedModes);

    if (value === "Bus" || value === "Flight") {
      const updatedFuels = [...fuelTypes];
      updatedFuels[index] = "";
      setFuelTypes(updatedFuels);
    }
  };

  const handleFuelChange = (index, value) => {
    const updatedFuels = [...fuelTypes];
    updatedFuels[index] = value;
    setFuelTypes(updatedFuels);
  };

 
  return (
    <div className="timeline">
      {stops.map((stop, index) => (
        <React.Fragment key={index}>
         <Stop
            label={stop.label}
            location={stop.location}
            onLocationChange={(value) =>
              setStops((prev) => {
                const updated = [...prev];
                updated[index].location = value;
                return updated;
              })
            }
            removable={stop.label === "New Stop" }
            onRemove={() => handleRemoveStop(index)}
          />
          {index < stops.length - 1 && (
            <div
              className="dropdown-container"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ marginRight: "10px", fontSize: "1.5em" }}>
                {travelModeEmojis[travelModes[index]] || ""}
              </span>
              <Dropdown
                options={[
                  "Bus",
                  "Cab",
                  "Walk",
                  "3-Wheeler",
                  "Car-Pool",
                  "2-Wheeler",
                  "Metro",
                  "Train",
                  "Flight",
                ]}
                value={travelModes[index]}
                onChange={(value) => handleTravelModeChange(index, value)}
              />

{travelModes[index] === "Car-Pool" && (
      <input
        type="number"
        placeholder="Seats"
        className="carpool-input"
        style={{ marginLeft: "10px", width: "60px" }}
      />
    )}

              {[
                "Bus",
                "Cab",
                "Walk",
                "3-Wheeler",
                "Car-Pool",
                "2-Wheeler",
                "Metro",
                "Train",
                "Flight",
              ].includes(travelModes[index]) && (
                <Dropdown
                  options={
                    fuelOptions[travelModes[index]] || fuelOptions.default
                  }
                  value={fuelTypes[index]}
                  onChange={(value) => handleFuelChange(index, value)}
                  disabled={["Train", "Walk", "Metro"].includes(
                    travelModes[index]
                  )}
                  style={{ marginLeft: "-24px", width: "120px" }}
                />
              )}
            </div>
          )}
          {index < stops.length - 1 && (
            <div className="add-stop-container">
              <button className="add-stop" onClick={() => handleAddStop(index)}>
                +
              </button>
              <div className="connector-line"></div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

function NewAddInfo() {
  const [travelMode, setTravelMode] = useState("Bus");
  const [stay, setStay] = useState("No"); 
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSave = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="new-add-info">
      <HeaderForm
        travelMode={travelMode}
        setTravelMode={setTravelMode}
        stay={stay}
        setStay={setStay}
      />
      <h2>
        Please fill out the information below to view your <b>emission summary</b>
      </h2>
      <Timeline travelMode={travelMode} stay={stay}  />
      <h3>
    
      <button className="info-save-button" onClick={handleSave}>
        Save
      </button>
      {isPopupOpen && <NewAddInfoPopup onClose={handleClosePopup} />}
        <i>
          Drive the Change: Choose Sustainable Transportation for a Greener
          tomorrow !
        </i>
      </h3>
    </div>
  );
}

export default NewAddInfo;